import { useState, useEffect } from 'react';
import axios from 'axios';
export default function MigationPopup({ keyID, reload, close }) {

  const [isDelayedMigration, setDelayedMigration] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('0');
    const [isLoading, setLoading] = useState(false);
    const [allServers, setAllServers] = useState([]);
    const [selectedServerTo, setSelectedServerTo] = useState('');


    useEffect(() => {
console.log({selectedServerTo})
    }, [selectedServerTo])

    // Supported on vless: ✅
    const getServers = async () => {
        setLoading(true)
        const request = await axios.post('https://dusk.masliukov.com/v4/vless/getList');
        const servers = request.data.keys;
        console.log(servers);
        setAllServers(servers);

        const filteredServersByPlan = servers.filter((server) => parseInt(server.plan) === parseInt(selectedPlan) && server.enabled === true);
        setSelectedServerTo(filteredServersByPlan[0].id);
        setLoading(false)
    };

    useEffect(() => {
        getServers();
    }, []);

    // Supported on vless: ✅
    const migrateUsers = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (keyID.length === 0) {
            return;
        }

        const data = {
            key: keyID,
            vless_server_id: selectedServerTo,
            is_delayed_migration: isDelayedMigration,
        };

        console.log({ data });
        const req = await axios.post('https://dusk.masliukov.com/v4/migrateUser/vless', data);
        if (req.data.ok) {
            reload();
        } else {
            alert('Something went wrong');
        }
        setLoading(false);
    };


    useEffect(() => {
        if (allServers.length === 0) {
            return;
        }

        setSelectedServerTo(allServers.filter((server) => parseInt(server.plan) === parseInt(selectedPlan) && server.enabled === true)[0].id);
    }, [selectedPlan]);

    return (
        <div className="overlay">
            <form onSubmit={migrateUsers} className="overlay-content">
                <div className="migration-container">
                    <select className="t-input" type="text" value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                        <option value={0}>Базовый</option>
                        <option value={3}>Семейный</option>
                        <option value={15}>Семейный / год</option>
                        <option value={1}>Премиум</option>
                        <option value={2}>Deeplink</option>
                        <option value={4}>Базовый / UK 🇬🇧</option>
                        <option value={5}>Базовый / Finland 🇫🇮</option>
                        <option value={6}>Базовый / Germany 🇩🇪</option>
                        <option value={10}>Базовый / USA 🇺🇸</option>
                        <option value={12}>Базовый / Rus 🇷🇺</option>
                        <option value={7}>Базовый / Yota, Tele2</option>
                        <option value={8}>Семейный / UK 🇬🇧</option>
                        <option value={9}>Семейный / Finland 🇫🇮</option>
                        <option value={13}>Семейный / Germany 🇩🇪</option>
                        <option value={14}>Семейный / USA 🇺🇸</option>
                        <option value={11}>Семейный / Yota, Tele2</option>
                        <option value={20}>1 Month / Я в Китае 🇨🇳</option>
                        <option value={21}>6 Month / Я в Китае 🇨🇳</option>
                    </select>

                    <select value={selectedServerTo} onChange={(e) => setSelectedServerTo(e.target.value)} className="t-input">
                        {allServers
                            .filter((server) => parseInt(server.plan) === parseInt(selectedPlan) && server.enabled === true)
                            .map((server) => {
                                return (
                                    <option value={server.id}>
                                        { server.is_proxy_enabled && '🧭'} <b>{server.comment}</b> {' | ' + server.key_url}
                                    </option>
                                );
                            })}
                    </select>
                </div>
                <button type="submit" disabled={isLoading} className="t-button">
                    {isLoading ? "⏳ Крутим винтики..." : "Мигрировать"}
                </button>
               	<div style={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
				<input type='checkbox' checked={isDelayedMigration} onChange={e => setDelayedMigration(e.target.checked)}/>
				<label>Should old configuration die within 2 hours after migration?</label>
				</div>

                <button onClick={() => close()} className="t-button">
                    Закрыть
                </button>
            </form>
        </div>
    );
}
