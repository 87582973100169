import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { useState, useEffect } from "react";
import axios from "axios";
import "./cards.css";
import Chart from "../components/Chart";
import CardTemplate from "../components/CardTemplate";
import MonthlyRound from "../components/MonthlyRound";
import ProjectAverageBill from "../components/ProjectAverageBill";

export default function Statistics() {
	const URL = "https://dusk.masliukov.com/v2-api/statistics/graph";
	const ACTIVE_STATS_URL = "https://dusk.masliukov.com/v2-api/statistics/activeStats";

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<div className="custom-tooltip-date">{`${new Date(label).toLocaleDateString()}`}</div>
					<div>
						{payload.map((pld) => (
							<div style={{ display: "flex" }}>
								<div> {pld.value} Users</div>
							</div>
						))}
					</div>
				</div>
			);
		}

		return null;
	};

	const [data, setData] = useState([]);
	const [data2, setData2] = useState([]);

	//const [allCustomers, setAllcustomers] = useState(0);

	const [total, setTotal] = useState(0);

	const [activeCustomers, setActiveCustomers] = useState(0);
	const [prevActiveCustomers, setPrevActiveCustomers] = useState(0);

	const [graph1, setGraph1] = useState(null);
	const [graph2, setGraph2] = useState(null);

	const [uniqueGraph, setUniqueGraph] = useState([]);

	const [activeOutlineKeys, setActiveOutlineKeys] = useState(0);
	const [activeVlessKeys, setActiveVlessKeys] = useState(0);
	const [activeWebUsers, setActiveWebUsers] = useState(0);
	const [activeWebUsersWithKeys, setActiveWebUsersWithKeys] = useState(0);

	const [activeVlessExclusive, setActiveVlessExclusive] = useState(0);

	useEffect(() => {
		loadStats();
		loadActiveStats();
	}, []);

	const calculateGraph = (graph) => {
		let c = 0;
		let p = 0;
		for (let i = 0; i < graph.length; i++) {
			p = p + parseInt(graph[i].total_revenue);
			c = c + parseInt(graph[i].order_count);
		}
		return { count: c, price: p };
	};

	const loadActiveStats = async () => {
		const req = await axios.post(ACTIVE_STATS_URL);
		const { data } = req;
		console.log(data);
		setActiveVlessKeys(data.activeVless);
		setActiveOutlineKeys(data.activeOutline);
		setActiveWebUsers(data.activeWebUsers);
		setActiveWebUsersWithKeys(data.activeWebUsersWithKeys);
		setActiveVlessExclusive(data.activeExclusiveVless);
	};

	const loadStats = async () => {
		const req = await axios.post(URL);

		setData(req.data.graph);
		setData2(req.data.ppp);

		const g1 = calculateGraph(req.data.graph);
		const g2 = calculateGraph(req.data.ppp);

		setGraph1(g1);
		setGraph2(g2);

		const { total, totalUniq } = req.data;

		setUniqueGraph(req.data.uniqueGraph);
		setPrevActiveCustomers(req.data.uniqueGraph[req.data.uniqueGraph.length - 2].amount);
		setTotal(total);
		setActiveCustomers(totalUniq);
	};

	return (
		<div className="content-block t-container">
			<div className="t-content">
				<h1>Analytics</h1>
				<div className="cards-container">
					<CardTemplate
						heading={"Users"}
						subheading={"Current users / Yesterday "}
						prevValue={prevActiveCustomers}
						value={activeCustomers}
					></CardTemplate>
					<CardTemplate heading={"Subscriptions"} subheading={"Current subscriptions"} value={total}></CardTemplate>
					<CardTemplate
						heading={"Revenue"}
						subheading={"This month / Previous"}
						type="rubles"
						value={graph1 !== null ? graph1.price : 0}
						prevValue={graph2 !== null ? graph2.price : 0}
					></CardTemplate>
					<CardTemplate
						heading={"Sales"}
						subheading={"This month / Previous"}
						value={graph1 !== null ? graph1.count : 0}
						prevValue={graph2 !== null ? graph2.count : 0}
					></CardTemplate>

					<CardTemplate heading={"Keys Outline"} subheading={"Active user keys"} value={activeOutlineKeys} prevValue={null}></CardTemplate>
					<CardTemplate
						heading={"Keys Vless"}
						subheading={"Active user keys"}
						value={`${activeVlessKeys} / ${activeVlessExclusive}`}
						forceValue={true}
						prevValue={null}
					></CardTemplate>
					<CardTemplate heading={"Web Users"} subheading={"With email confirmed"} value={activeWebUsers} prevValue={null}></CardTemplate>
					<CardTemplate
						heading={"Web Users"}
						subheading={"With active keys"}
						value={activeWebUsersWithKeys}
						prevValue={null}
					></CardTemplate>
				</div>
				<Chart graph={graph1} days="30" days2="30-60" data={data} graph2={graph2} data2={data2} />
				<MonthlyRound />
				<h1>Active Paying Users</h1>
				<div style={{ height: "500px" }}>
					<ResponsiveContainer className="custom-container">
						<LineChart margin={{ top: 32, left: 32, right: 32, bottom: 32 }} width={500} height={500} data={uniqueGraph}>
							<Line dot={false} name="Users: " type="monotone" dataKey="amount" stroke="#FFF" />
							<XAxis hide={true} name="OrderDate: " dataKey="date" />
							<Tooltip content={<CustomTooltip />} />
							<YAxis hide={true} domain={[0, 20000]} />
						</LineChart>
					</ResponsiveContainer>
				</div>
				{/* <h1>LTV and Unique Orders</h1> */}
				{/* <ProjectLTV uniqueGraph={uniqueGraph} /> */}

				<h1>Average Order Value</h1>
				<ProjectAverageBill />
			</div>
		</div>
	);
}
