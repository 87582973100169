import React from "react";

export default function CardTemplate({ heading, subheading, value, prevValue, type = "integer", forceValue = false }) {
	const calculatePercentageDifference = (currentValue, previousValue) => {
		const difference = parseInt(currentValue) - parseInt(previousValue);
		const percentageDifference = (difference / previousValue) * 100;
		if (percentageDifference % 1 !== 0) {
			return percentageDifference.toFixed(2).replace(/\.00$/, ""); // Remove .00 if present
		} else {
			return percentageDifference; // Return the whole number as is
		}
	};

	return (
		<div className="s-card-template">
			<div className="s-card-heading">{heading}</div>
			<div className="s-card-subheading">{subheading}</div>
			<div className="s-card-value">
				{!forceValue && parseInt(value).toLocaleString("en-US")}
				{forceValue && value}
				{type === "rubles" && "₽"}
				{prevValue && (
					<span className={value > prevValue ? "span-green" : "span-red"}>
						{value > prevValue ? "+" : ""}
						{calculatePercentageDifference(value, prevValue)}%
					</span>
				)}
			</div>
		</div>
	);
}
